import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'malmo-fashion-block',
  templateUrl: './fashion-block.component.html',
  styleUrls: ['./fashion-block.component.scss']
})
export class FashionBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
