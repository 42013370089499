import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Product } from '@infrab4a/connect';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'malmo-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() product: Product;
  @Input() isSubscriber: boolean;
  @Input() position: number;
  discountPercentage: number;
  SHOP: "mensmarket";
  ratingAverage: number;
  ratingCount: number;
  star: TemplateRef<unknown>;

  constructor(
    private ngbConfig: NgbRatingConfig,
  ) { this.ngbConfig.max = 5;}

  ngOnInit(): void {
    this.ratingCount = this.product?.reviews?.filter((r) => r.status).length || 0;
    this.ratingAverage = this.ratingCount ? (this.product?.reviews?.filter((r) => r.status)
      .reduce((sum, review) => sum += review.rate, 0) / this.ratingCount) : 0;
  }

}
