import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { images } from 'src/app/shared/models/images';
import { Utils } from 'src/app/shared/utils/Utils';
import data from 'src/assets/data/believes.json';
import { environment as ENV } from 'src/environments/environment';

@Component({
  selector: 'malmo-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  isProduction = false;
  innerWidth: number;

  believes: images[];

  constructor(
    private meta: Meta,
    private titleService: Title
  ) {
    this.isProduction = ENV.production;
    this.innerWidth = window.innerWidth;
    this.believes = data.believes;
  }

  ngOnInit(): void {
    this.titleService.setTitle("Malmo | Quem somos e o que fazemos");
    this.meta.addTag({
      name: 'author',
      content: 'B4A'
    });
    this.meta.updateTag({
      name: 'description',
      content:
        'A Malmo tem como objetivo conectar você com o seu estilo.Trabalhamos no desenvolvimento de acessórios de qualidade, estilosos e com custo-benefício!'
    });
  }

  handleSlideToSection(section: string) {
    Utils.slideToSection(section);
  }
}
