import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EllipsisModule } from 'ngx-ellipsis';

import { CategoriesComponent } from './components/categories/categories.component';
import { FooterComponent } from './components/footer/footer.component';
import { InstagramCarouselComponent } from './components/instagram-carousel/instagram-carousel.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductsComponent } from './components/products/products.component';
import { ReviewCardComponent } from './components/review-card/review-card.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { SliderBannerComponent } from './components/slider-banner/slider-banner.component';
import { CarouselModule, CategoriesModule, NewsletterModule, OwnBrandsValuesModule } from '@infrab4a/components-b4a';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FashionBlockComponent } from './components/fashion-block/fashion-block.component';

const COMPONENTS = [
  FooterComponent,
  NewsletterComponent,
  SliderBannerComponent,
  ProductCardComponent,
  ProductsComponent,
  ProductCardComponent,
  ReviewCardComponent,
  ReviewsComponent,
  FashionBlockComponent,
  CategoriesComponent,
  InstagramCarouselComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    EllipsisModule,
    NgbModule,
    NgbAccordionModule,
    NgbModalModule,
    CarouselModule,
    BrowserAnimationsModule,
    CarouselModule,
    OwnBrandsValuesModule,
    CategoriesModule,
    NewsletterModule,
  ],
  exports: [
    COMPONENTS,
    NgbModule,
    NgbAccordionModule,
    NgbModalModule,
    CarouselModule,
    BrowserAnimationsModule,
  ],
})
export class SharedModule {}
