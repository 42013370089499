import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ResizeAppService } from 'src/app/core/services/resize-application.service';
import * as componentsB4a from '@infrab4a/components-b4a';

import { carouselBanner } from '../../constants/slides';
import { Banner } from '../../models/ banner';

@Component({
  selector: 'malmo-slider-banner',
  templateUrl: './slider-banner.component.html',
  styleUrls: ['./slider-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderBannerComponent implements OnInit {
  @Input() bannerItems: Banner[];

  isMobile: boolean;

  readonly mobileWidthSize = 768;

  carousel: componentsB4a.B4aCarouselComponent<any>;
  carouselIsFirst = true;
  carouselIsLast = false;
  points: number[] = [];

  @ViewChild('myCarousel')
  set _myCarousel(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
      this.carousel = myCarousel;
      setTimeout(() => {
          this.points = myCarousel.pointNumbers;
      });
  }
  carouselTile: componentsB4a.B4aCarouselConfig;

  constructor(private _resizeAppService: ResizeAppService) {
    this.carouselTile = carouselBanner;
    this.isMobile = this._resizeAppService.isMobileWidthSize;
  }

  ngOnInit(): void {
    this._resizeAppService.onResize().subscribe(() => {
      if (this._resizeAppService.isMobileWidthSize) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }

  onCarouselMove(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
    this.carouselIsFirst = myCarousel.isFirst;
    this.carouselIsLast = myCarousel.isLast;
    if (myCarousel.pointNumbers) {
        this.points = myCarousel.pointNumbers;
    } else {
        this.points = [];
    }
  }

  private getBannerImage(banner: Banner): string {
    return banner.image[this.isMobile ? 'mobile' : 'desktop'];
  }

  getBanner(banner: Banner): string {
    const backgroundImage = ` url(${this.getBannerImage(
      banner
    )})`;

    return backgroundImage;
  }

  getMaxWidthContent(index: number) {
    let maxWidth: string;

    if (this.isMobile) {
      maxWidth = '343px';
    } else {
      if (index === 0) {
        maxWidth = '488px';
      } else {
        maxWidth = '590px';
      }
    }

    return maxWidth;
  }
}
