import { Component, OnInit } from '@angular/core';
import { FooterModel } from '../../models/footer.model';

import data from 'src/assets/data/links-footer.json';

@Component({
  selector: 'malmo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  linksB4a: FooterModel[];
  linksMens: FooterModel[];
  footer: FooterModel[];

  constructor() {
    this.linksB4a = data.b4a;
    this.linksMens = data.mens;
    this.footer = data.footer;
  }

  ngOnInit(): void {}

  toggleLinkItem(item: FooterModel): void {
    item.isOpened = !item.isOpened;
  }
}
