import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable } from 'rxjs';

import { MenuHeaderService } from './core/services/menu-header.service';
import { ResizeAppService } from './core/services/resize-application.service';

import { Menu } from './shared/models/menu.model';
import m from '../assets/data/menu.json';
import data from 'src/assets/data/links-footer.json';
import { FooterModel } from './shared/models/footer.model';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'malmo';
  menu: Menu[] = [];
  logo: string;
  closeButton: string;
  linksB4a: FooterModel[];
  linksBrand: FooterModel[];
  footer: FooterModel[];
  logoFooter: string;

  alert$: Observable<string> | undefined;

  constructor(
    private _resizeAppService: ResizeAppService,
    private _menuHeaderService: MenuHeaderService,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.menu = m.menu;
    this.logo = 'assets/img/logo.png';
    this.closeButton = 'assets/img/svg/close.svg';
    this.linksB4a = data.b4a;
    this.linksBrand = data.mens;
    this.footer = data.footer;
    this.logoFooter = 'assets/img/logo-white.png';
    this.onResize();
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page_view',
          pageUrl: event.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });    // this._alertDialogService.showAlert('Frete grátis acima de R$ 250,00');
    // this.alert$ = this._alertDialogService.alert$;
  }

  // close() {
  //   this._alertDialogService.closeAlert();
  // }

  private onResize() {
    this._resizeAppService.onResize().subscribe(() => {
      this._menuHeaderService.close();
    });
  }
}
