<div
    class="container product-card onStock"
    id="{{ position }}"
>

    <div class="image text-center">
        <figure>
            <img
                [src]="product.images[0]"
                [alt]="product.name"
                class
            >
        </figure>
    </div>
    <div class="button-container">
        <a
            [href]="'https://mensmarket.com.br/products/'+ product.slug"
            target="_blank"
        >
            <button class="btn-tertiary">
                <svg
                    id="Camada_1"
                    data-name="Camada 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                >
                    <path
                        class="cls-1"
                        d="M76.8408,38.42h-6C70.8408,20.5439,61.4912,6,50,6S29.1592,20.5439,29.1592,38.42h-6C23.1592,17.2354,35.2,0,50,0S76.8408,17.2354,76.8408,38.42Z"
                    ></path>
                    <path
                        class="cls-1"
                        d="M96.3184,100H3.6816A2.9993,2.9993,0,0,1,.7168,96.5439l8.1406-52.915a16.6818,16.6818,0,0,1,8.3057-14.1411l3.0078,5.1914a10.6629,10.6629,0,0,0-5.3154,9.19,2.9621,2.9621,0,0,1-.0352.4561L7.1787,94h85.73l-6.378-49.749a2.9308,2.9308,0,0,1-.0244-.3814,10.6256,10.6256,0,0,0-6.3759-9.7329l2.4043-5.498a16.6265,16.6265,0,0,1,9.9707,15.03l6.788,52.9493A3.0008,3.0008,0,0,1,96.3184,100Z"
                    ></path>
                    <path
                        class="cls-1"
                        d="M20.1709,34.6792l-3.0078-5.1914a16.6094,16.6094,0,0,1,8.3154-2.2412H75.8838a16.4934,16.4934,0,0,1,6.6523,1.393L80.13,34.1357a10.5288,10.5288,0,0,0-4.2461-.8891H25.4785A10.6078,10.6078,0,0,0,20.1709,34.6792Z"
                    ></path>
                </svg>
                Comprar
            </button>
        </a>

    </div>
    <ngb-rating
        [(rate)]="ratingAverage"
        [readonly]="true"
        [starTemplate]="starHalf"
    >
    </ngb-rating>
    <span class="product-name">{{product.name}}</span>

    <div class="price-box">

        <div class="price-group">
            <p class="price-item">
                <span class="price-label">De:</span>
                <span class="price-value price-value-full">
                    {{ product.price.fullPrice| currency: "BRL" }}
                </span>
            </p>

            <p class="price-item">
                <span class="price-label">Por:</span>
                <span class="sale-price">
                    {{ product.price.price | currency: "BRL" }}
                </span>
            </p>
        </div>

        <p
            *ngIf="product.price.subscriberPrice"
            class="price-item-subscriber"
        >
            <span class="price-label price-label-subscriber">Clube</span>
            <span class="sale-price price-value-subscriber">
                {{ product.price.subscriberPrice | currency: "BRL" }}
            </span>
        </p>

    </div>

</div>

<ng-template
    #starHalf
    let-fill="fill"
    let-index="index"
>
    <span
        class="star"
        [class.full]="fill === 100"
    >
        <span
            class="half"
            [style.width.%]="fill"
        >
            &#9733;
        </span>
        &#9733;
    </span>
</ng-template>
