import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ProductModel } from '../../models/product.model';

import { carouselReview } from '../../constants/slides';

import * as componentsB4a from '@infrab4a/components-b4a';
@Component({
  selector: 'malmo-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewsComponent {

  @Input() reviewedProducts: ProductModel[];
  @Input() reviewPoints: number[];
  @Input() loading: boolean;
  innerWidth: number;
  more: boolean = false;
  buttonText: string = "Ver mais avaliações";

  carousel: componentsB4a.B4aCarouselComponent<any>;
  carouselIsFirst = true;
  carouselIsLast = false;
  points?: number[] = [];
  position: number = 0;

  @ViewChild('carouselReview')
  set _carouselReview(carouselReview: componentsB4a.B4aCarouselComponent<any>) {
    this.carousel = carouselReview;
    setTimeout(() => {
      this.points = this.reviewPoints;
    });
  }

  carouselTile: componentsB4a.B4aCarouselConfig;

  carouselTileMobile: componentsB4a.B4aCarouselConfig;

  constructor() {
    this.carouselTile = carouselReview;
  }

  next() {
    if (this.position >= (this.points.length - 1)) {
      this.position = this.points.length - 1;
    } else {
      this.position = this.position + 1;
    }
  }

  prev() {
    if (this.position <= 0) {
      this.position = 0;
    } else {
      this.position = this.position - 1;
    }
  }

  onCarouselMove(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
    this.carouselIsFirst = myCarousel.isFirst;
    this.carouselIsLast = myCarousel.isLast;
    const firstPosition = 0;
    const lastPosition = this.points.length - 1;
    if (this.position === firstPosition) {
      myCarousel.isFirst = true;
    } else {
      myCarousel.isFirst = false;
    };
    if (this.position === lastPosition) {
      myCarousel.isLast = true;
    } else {
      myCarousel.isLast = false;
    };
  }

  moveTo(carousel: any, position: number) {
    carousel.moveTo(position);
    this.position = position;
    this.onCarouselMove(carousel);
  }

  seeMore(){
    this.more = !this.more;
    if(this.more) this.buttonText = "Ver menos avaliações";
    else this.buttonText = "Ver mais avaliações";
  }

  getSkeletonTheme(): Object {
    return {
      width: "210px",
      height: "315px",
      "border-radius": "4px",
      "background-color": "#f2f2f2",
    };
  }

  getSkeletonCount(): number {
    if (this.innerWidth <= 425) {
      return 1;
    } else if (this.innerWidth > 425 && this.innerWidth <= 585) {
      return 2;
    } else if (this.innerWidth > 585 && this.innerWidth <= 768) {
      return 3;
    } else if (this.innerWidth > 768 && this.innerWidth <= 1366) {
      return 4;
    } else if (this.innerWidth > 1366 && this.innerWidth <= 1520) {
      return 5;
    } else if (this.innerWidth > 1366 && this.innerWidth <= 1640) {
      return 6;
    } else if (this.innerWidth > 1640 && this.innerWidth <= 1920) {
      return 7;
    } else {
      return 8;
    }
  }

  get skeletonReviewedProductCount(): number {
    if (this.innerWidth <= 480) {
      return 1;
    } else if (this.innerWidth > 480 && this.innerWidth <= 768) {
      return 2;
    } else if (this.innerWidth > 768 && this.innerWidth <= 1080) {
      return 3;
    } else if (this.innerWidth > 1080 && this.innerWidth <= 1620) {
      return 4;
    } else if (this.innerWidth > 1620 && this.innerWidth <= 1920) {
      return 5;
    } else {
      return 6;
    }
  }

  get skeletonReviewedProductTheme(): Object {
    return {
      width: "280px",
      height: "449px",
      "border-radius": "16px",
      "background-color": "#f2f2f2",
    };
  }
}
